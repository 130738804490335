<template>
    <div class="login_form">
        <HeaderNav></HeaderNav>
        <div class="box">
            <div class="top">
                <p class="title">{{$t('new.exclusiveAlliancePlan')}}</p>
                <div style="padding: 4px 16px;color: #fff">
                    {{$t('new.joinMogo')}}
                </div>
                <img class="top-img" src="../assets/common/top.png" alt="">
                <div class="txt"> {{$t('new.becomePartner')}}</div>
            </div>
        </div>
        <FootC></FootC>
        <FundPwd :fundPwdPopup="fundPwdPopup"></FundPwd>
        <Main-tab-bar></Main-tab-bar>
    </div>
</template>

<script>
import FootC from "@/components/FootC.vue";
import MainTabBar from '../components/tabbar/MainTabBar'
import moment from 'moment-timezone'
import HeaderNav from "@/components/HeaderNav.vue";
import FundPwd from "@/components/FundPwd.vue";

export default {
    components: {
        FundPwd,
        MainTabBar,
        HeaderNav,
        FootC
    },

    data() {
        return {
            lang: '',
            fundPwdPopup: false,
            showSearch: false,
            search: null,
            tabList: [
                {type: 1, path: '', title: '收藏夹'},
            ]
        }
    },

    computed: {
        isLogin: function () {
            return this.$store.getters.isLogin;
        },
        userInfo: function () {
            return this.$store.getters.member || localStorage.getItem("MEMBER");
        },
        currentTime() {
            return moment.tz.guess();
        },
    },
    watch: {},
    methods: {}
}

</script>

<style scoped lang="scss">
.login_form {
    background: #0E212E;
    position: relative;
    overflow: hidden;
    text-align: center;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 68px);
}

.box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;

    .top {
        width: 96%;
        height: 500px;
        margin: 70px auto 30px;
        border-radius: 6px;
        background-color: #243542;
        position: relative;

        .title {
            font-size: 26px;
            font-weight: bold;
            color: #fff;
            padding: 10px;
        }

        .top-img{
            width: 70%;
        }

        .txt{
            position: absolute;
            padding: 20px;
            width: 150px;
            height: 50px;
            background-color: #1C70E2;
            border-radius: 6px;
            color: #fff;
            font-weight: bold;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: 40px;
            left: 0;
            right: 0;
            margin:auto;
        }
    }

}

</style>
